import React, { useEffect, useState } from "react"
import { Layout, Menu, Image, Avatar, Button, Form, Input, Modal, message, AutoComplete, Spin } from 'antd'
import useLocalStorageState from 'use-local-storage-state'
import {
    CodeTwoTone, DashboardTwoTone, ExceptionOutlined, SettingTwoTone, PieChartTwoTone,
    FileExclamationOutlined, RobotOutlined, BlockOutlined, FileWordTwoTone, DatabaseOutlined, SettingOutlined,
    AlertTwoTone, ShopTwoTone, FireTwoTone, PayCircleOutlined, RocketOutlined, UserOutlined, AccountBookOutlined,
    DeploymentUnitOutlined, GoldTwoTone, HourglassOutlined
} from '@ant-design/icons';
import { Link, useHistory } from "react-router-dom"
import { connect } from "react-redux";

import { Session } from './store/global/actions'
import RequireRolesOr from "./components/require_roles_or";
import GlobalSearchInput from "./components/global_search_input";
import Config from "./config"

import LOGO from './images/logo.png'
import "./Nav.css"
const { Header } = Layout;


function Nav(props) {
    const setLocalStorageUser = useLocalStorageState('sign-in-user', null)[1]
    const history = useHistory()
    const [editPasswordForm] = Form.useForm();

    const logOut = () => {
        props.logOut()
        setLocalStorageUser(null)
        history.replace("/")
    }

    const changePasswordConfirm = () => {
        const handleEditPasswordSubmit = (values, modalClose) => {
            props.jsonRequest.put("/api/password", values)
                .then(response => response.json())
                .then(({ data, message: msg }) => {
                    if (!!data && !!data.success) {
                        message.success({ content: <span>密码修改成功，建议<a href="#/" onClick={() => { logOut(); message.destroy() }}>重新登录</a></span>, duration: 5 })
                        modalClose()
                    }
                    else
                        message.warn(msg)
                })
        }

        Modal.confirm({
            title: '修改密码',
            content: <Form form={editPasswordForm} name="basic" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} autoComplete="off">
                <Form.Item label="原密码" name="origin_password" rules={[{ required: true, message: '原始密码能为空' }]}>
                    <Input.Password />
                </Form.Item>

                <Form.Item label="新密码" name="new_password" rules={[{ required: true, message: '新密码不能为空' }]}>
                    <Input.Password />
                </Form.Item>
            </Form>,
            onOk: (close) => {
                editPasswordForm
                    .validateFields()
                    .then(values => {
                        editPasswordForm.resetFields();
                        handleEditPasswordSubmit(values, close);
                    })
                    .catch(info => {
                        console.log('Validate Failed:', info);
                    });
                return false
            }
        });
    }

    return <Layout className="layout" >
        <Header style={{ background: "#fff", padding: 0, display: "flex" }}>
            <div className="logo">
                <Image src={LOGO} preview={false} width={64} />
            </div>

            <Menu theme="light" mode="horizontal" selectedKeys={[props.activeKey]} style={{ border: "none", flexGrow: 1 }}>
                {RequireRolesOr.allow(["acupoint:role::operator"]) &&
                    <Menu.Item key="Dashboard">
                        <Link to="/dashboard"><DashboardTwoTone /> 仪表盘</Link>
                    </Menu.Item>
                }

                <RequireRolesOr require={["acupoint:role::customer-service", "acupoint:role::operator"]}>
                    <Menu.SubMenu key="MEMBER-OPERATION" title={<Link to="/operation" className="nav-link">运营 Operation</Link>} icon={<ShopTwoTone />}>

                        <Menu.Item key="Operation" icon={<SettingOutlined style={{ color: "#1890ff" }} />}>
                            <Link to="/ops">运维</Link>
                        </Menu.Item>

                        <Menu.Item key="MEMBER" icon={<UserOutlined style={{ color: "#1890ff" }} />}>
                            <Link to="/ops/member">用户</Link>
                        </Menu.Item>

                        <Menu.Item key="HOT-SEARCH" icon={<FireTwoTone />}>
                            <Link to="/ops/hot-search">热搜</Link>
                        </Menu.Item>

                        <Menu.Item key="LAUNCH-SCREEN" icon={<RobotOutlined style={{ color: "#1890ff" }} />} >
                            <Link to="/ops/launch-screen">开屏页</Link>
                        </Menu.Item>


                        <Menu.Item key="GRAPH-SUGGEST" icon={<DeploymentUnitOutlined style={{ color: "#1890ff" }} />} >
                            <Link to="/ops/graph/suggest">知识图谱“换一换”</Link>
                        </Menu.Item>
                        <Menu.Item key="APP-VERSION" icon={<BlockOutlined style={{ color: "#1890ff" }} />} >
                            <Link to="/ops/versions">APP发行版本管理</Link>
                        </Menu.Item>

                        <Menu.SubMenu key="NOTIFICATION" title="消息通知 Notification" icon={<SettingTwoTone />}>
                            <Menu.Item key="NOTIFICATION-CHANNEL" icon={<AlertTwoTone />} >
                                <Link to="/ops/notification/channel">通知频道管理</Link>
                            </Menu.Item>
                            <Menu.Item key="NOTIFICATION-SCENE" icon={<AlertTwoTone />} >
                                <Link to="/ops/notification/scene">场景</Link>
                            </Menu.Item>
                            <Menu.Item key="NOTIFICATION-MESSAGE" icon={<AlertTwoTone />} >
                                <Link to="/ops/notification/message">已发送的消息通知</Link>
                            </Menu.Item>
                        </Menu.SubMenu>

                        <Menu.SubMenu key="FEEDBACK" title="用户反馈 Feedback" icon={<FileExclamationOutlined style={{ color: "#1890ff" }} />}>
                            <Menu.Item key="FEEDBACK-APP" icon={<FileExclamationOutlined style={{ color: "#1890ff" }} />} >
                                <Link to="/ops/feedback/app">APP反馈</Link>
                            </Menu.Item>
                            <Menu.Item key="FEEDBACK-DIAGNOSIS_SIM" icon={<FileExclamationOutlined style={{ color: "#1890ff" }} />} >
                                <Link to="/ops/feedback/diagnosis_sim">中济学堂反馈</Link>
                            </Menu.Item>
                            <Menu.Item key="FEEDBACK-CORRECTION" icon={<FileExclamationOutlined style={{ color: "#1890ff" }} />} >
                                <Link to="/ops/feedback/correction">纠错</Link>
                            </Menu.Item>
                        </Menu.SubMenu>

                        <Menu.SubMenu key="SETTING" title="配置 Operation" icon={<SettingTwoTone />}>
                            <Menu.Item key="SETTING-CUSTOMER-SERVICE" icon={<AlertTwoTone />} >
                                <Link to="/ops/settings/CustomerServiceNotice">客服弹窗提示</Link>
                            </Menu.Item>
                            <Menu.Item key="SETTING-RUNTIME-SCHEDULER" icon={<HourglassOutlined style={{ color: "#1890ff" }} />} >
                                <Link to="/ops/runtime_scheduler">定时调度</Link>
                            </Menu.Item>
                            <Menu.Item key="SETTING-HEALTH-GUIDANCE" icon={<AlertTwoTone />} >
                                <Link to="/ops/settings/HealthGuidance">健康咨询</Link>
                            </Menu.Item>
                            <Menu.Item key="SETTING-HOME-BANNER-AD" icon={<AlertTwoTone />} >
                                <Link to="/ops/settings/HomeBannerAD">首页横幅广告</Link>
                            </Menu.Item>
                        </Menu.SubMenu>
                    </Menu.SubMenu>
                </RequireRolesOr>

                {RequireRolesOr.allow(["acupoint:role::customer-service", "acupoint:role::operator"]) &&
                    <Menu.Item key="Online" icon={<AlertTwoTone />}>
                        <Link to="/online/customer-service">在线 / 客服</Link>
                    </Menu.Item>}

                {RequireRolesOr.allow(["acupoint:role::operator", "acupoint:role::finance", "acupoint:role::customer-service"]) &&
                    <Menu.Item key="Statistics" icon={<PieChartTwoTone />}><Link to="/statistics/trade-order-records">统计 / 日志</Link></Menu.Item>
                }

                <RequireRolesOr require={["acupoint:role::editor", "acupoint:role::operator"]}>
                    <Menu.SubMenu key="CMS-ALL" title={<Link to="/cms/video-course-list" className="nav-link">内容管理 CMS</Link>} icon={<FileWordTwoTone />}>
                        <Menu.Item key="CMS" >
                            <Link to="/cms/wiki/articles">内容栏目</Link>
                        </Menu.Item>
                        <Menu.Item key="ENTRY" >
                            <Link to="/cms/entry/list">词条</Link>
                        </Menu.Item>
                        <Menu.Item key="VIDEO-COURSE" >
                            <Link to="/cms/video-course-list">视频课程</Link>
                        </Menu.Item>
                        <Menu.Item key="SQEC">
                            <Link to="/sqec/dashboard">学题考证 SQEC</Link>
                        </Menu.Item>
                        <Menu.Item key="MERIDIAN" >
                            <Link to="/cms/meridian/ancestor">经络</Link>
                        </Menu.Item>
                        <Menu.Item key="ACUPOINT" >
                            <Link to="/cms/acupoint/ancestor">穴位</Link>
                        </Menu.Item>
                        <Menu.Item key="ILLNESS" >
                            <Link to="/cms/illness">疾病</Link>
                        </Menu.Item>
                        <Menu.Item key="HERB" >
                            <Link to="/cms/herb">中草药</Link>
                        </Menu.Item>
                        <Menu.Item key="FOOD" >
                            <Link to="/cms/food">食物</Link>
                        </Menu.Item>
                        <Menu.SubMenu key="TERM" title="名词解释 Term">
                            <Menu.Item key="/cms/term/BasicTheory">
                                <Link to="/cms/term/BasicTheory">
                                    <span>基础理论</span>
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="TERM-Terminology">
                                <Link to="/cms/term/Terminology">
                                    <span>术语</span>
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="TERM-Doctor">
                                <Link to="/cms/term/Doctor">
                                    <span>医家</span>
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="TERM-AncientBook">
                                <Link to="/cms/term/AncientBook">
                                    <span>典籍</span>
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="TERM-DietTherapy">
                                <Link to="/cms/term/DietTherapy">
                                    <span>药膳</span>
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="TERM-Regimen">
                                <Link to="/cms/term/Regimen">
                                    <span>保健养生</span>
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="TERM-Poetics">
                                <Link to="/cms/term/Poetics">
                                    <span>经络歌赋</span>
                                </Link>
                            </Menu.Item>
                        </Menu.SubMenu>
                    </Menu.SubMenu>
                </RequireRolesOr>


                {RequireRolesOr.allow(["acupoint:role::finance"]) &&
                    <Menu.Item key="FINANCE" icon={<PayCircleOutlined style={{ color: "#1890ff" }} />}>
                        <Link to="/finance/pricing/acupoint:shelve::vip">财务 Finance</Link>
                    </Menu.Item>}


                {RequireRolesOr.allow(["acupoint:role::operator", "acupoint:role::marketing"]) &&
                    <Menu.Item key="MARKETING" icon={<AccountBookOutlined style={{ color: "#1890ff" }} />}>
                        <Link to="/marketing">市场 Marketing</Link>
                    </Menu.Item>}

                {RequireRolesOr.allow(["acupoint:role::operator", "acupoint:role::customer-service"]) &&
                    <Menu.Item key="COMMUNITY" icon={<GoldTwoTone />}>
                        <Link to="/community/simulator">社区 Community</Link>
                    </Menu.Item>
                }

                <RequireRolesOr require={["acupoint:role::operator"]}>
                    <Menu.SubMenu key="TECH-OPERATION" title="技术运维" icon={<CodeTwoTone />}>
                        <Menu.Item key="CACHE" icon={<DatabaseOutlined style={{ color: "#1890ff" }} />}>
                            <Link to="/tech/database/cache/Acupoint.Cache">缓存 DATA</Link>
                        </Menu.Item>

                        <Menu.Item key="Push" icon={<RocketOutlined style={{ color: "#1890ff" }} />}>
                            <Link to="/tech/push/history">推送 Push</Link>
                        </Menu.Item>

                        <RequireRolesOr key="EXCEPTION-WRAPPER" require={["acupoint:role::manager"]}>
                            <Menu.Item key="EXCEPTION" icon={<ExceptionOutlined style={{ color: "#1890ff" }} />}>
                                <Link to="/tech/exception">异常 Exception</Link>
                            </Menu.Item>
                        </RequireRolesOr>
                        <RequireRolesOr key="EXCEPTION-WRAPPER" require={["acupoint:role::manager"]}>
                            <Menu.Item key="EXCEPTION" icon={<ExceptionOutlined style={{ color: "#1890ff" }} />}>
                                <Link to="/tech/operation_log">操作日志 OperationLog</Link>
                            </Menu.Item>
                        </RequireRolesOr>
                    </Menu.SubMenu>
                </RequireRolesOr>

                {/* <Menu.Item key="Setting" >
                    <Link to="/setting"><SettingOutlined style={{ color: "#1890ff" }} /> 配置</Link></Menu.Item> */}
            </Menu>
            <div className="side">
                <GlobalSearchInput history={props.history} />
                <span style={{ padding: "4px 15px" }}><Avatar src={Config.AvatarUrl(props.user.avatar)} style={{ backgroundColor: '#87d068' }} /> {props.user.nickname}</span>
                <Button type="link" onClick={() => changePasswordConfirm()}>修改密码</Button>
                <Button type="link" onClick={() => logOut()}>退出</Button>
            </div>
        </Header>
    </Layout>
}

const mapStateToProps = ({ global }) => {
    return {
        socket: global.socket,
        activeKey: global.navActiveKey,
        user: global.user,
        jsonRequest: global.jsonRequest,
        globalChannel: global.globalChannel
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        logOut: () => {
            dispatch(Session.logOut())
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Nav);