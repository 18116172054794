import React from "react";
import { connect } from "react-redux";
import UserAvatar from "../../components/user/UserAvatar";
import { message, Row, Col, Statistic, Tooltip, Button, Table, Form, Input, Radio, Tabs } from 'antd';
import 'jsoneditor-react/es/editor.min.css';
import { InfoCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import UTCTimeAgo from "../../components/utc_time_ago"
import LocalTimeAgo from "../../components/local_time_ago"
import dayjs from "dayjs";

const formItemLayout = {
    labelCol: {
        xs: { span: 10 },
    },
    wrapperCol: {
        xs: { span: 14 },
    },
};
const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 4,
            offset: 10,
        },
    },
};

// 结算类型翻译
const settleTypeLabel = (value) => {
    switch (value) {
        case "cycle":
            return "循环"

        case "single":
            return "单次"
        default:
            break;
    }
}

const { TabPane } = Tabs;
class InviteCodeDetail extends React.Component {

    state = {
        data: null,
        invite_code_rewards: [],
        invite_code_logs: {},
        invite_code_settle_cycles: {},
        invite_code_reward_converts: {}
    }

    componentDidMount() {
        this.channelJoin(this.props.code)
        this.setState({ code: this.props.code })
    }

    componentWillUnmount() {
        !!this.state.channel && this.state.channel.leave()
    }


    UNSAFE_componentWillReceiveProps(props) {
        if (props.code !== this.state.code) {
            this.setState({ code: props.code })
            this.channelJoin(props.code)
        }
    }

    pushEvent(event, params, opts = null) {
        return this.state.channel.push(event, params)
    }

    channelJoin(code) {
        if (!!this.state.channel) {
            this.state.channel.off("data")
            this.state.channel.leave()
        }

        const channel = this.props.socket.channel(`marketing:invite_code:detail:${code}`)
        channel.on("data", ({ data }) => this.setState({ data }))
        channel.join()
        this.setState({ channel }, () => this.handleRefresh())
    }

    loadInviteLogPagnation(page, size) {
        this.pushEvent("get:invite_log:pagination", { page: page || this.state.invite_code_logs?.page || 1, size: size || 20 })
            .receive("ok", (data) => {
                this.setState({ invite_code_logs: data })
            })
    }

    loadRewardConvertPagnation(page, size) {
        this.pushEvent("get:reward_converts:pagination", { page: page || this.state.invite_code_reward_converts?.page || 1, size: size || 20 })
            .receive("ok", (data) => {
                this.setState({ invite_code_reward_converts: data })
            })
    }

    handleTabsChange(key) {
        switch (key) {
            case "join_log":
                this.loadInviteLogPagnation()
                break;
            case "reward_convert":
                this.loadRewardConvertPagnation()
                break;
            default:
                break;
        }
    }

    // 刷新数据
    handleRefresh() {
        this.loadInviteLogPagnation()
        this.loadRewardConvertPagnation()
    }

    // 处理提取审核表单
    handleRewardConvertAuditFormSubmit(invite_reward_convert_id, values) {
        this.pushEvent("audit:convert", { invite_reward_convert_id, ...values })
            .receive("ok", () => {
                this.handleRefresh()
                message.success("提交了审核")
            })
    }

    // 处理提取确认发放
    handleRewardConvertRelease(invite_reward_convert_id) {
        this.pushEvent("release:convert", { invite_reward_convert_id })
            .receive("ok", () => {
                this.handleRefresh()
                message.success("确认了发放")
            })
    }

    // 提取审核表单
    convertAuditForm(invite_reward_convert_id) {
        return <Form {...formItemLayout} onFinish={(values) => this.handleRewardConvertAuditFormSubmit(invite_reward_convert_id, values)}>
            <Form.Item name="status" initialValue="wait_release" label="审核意见" rules={[{ required: true }]}>
                <Radio.Group>
                    <Radio value="wait_release">通过</Radio>
                    <Radio value="failed">不通过</Radio>
                </Radio.Group>
            </Form.Item>
            <Form.Item name="failed_message" label="不通过原因" help="可不填">
                <Input.TextArea />
            </Form.Item>
            <Form.Item name="return" initialValue={false} label="返还积分" rules={[{ required: true }]}>
                <Radio.Group>
                    <Radio value={true}>返还</Radio>
                    <Radio value={false}>不返还</Radio>
                </Radio.Group>
            </Form.Item>
            <Form.Item {...tailFormItemLayout}>
                <Button type="primary" htmlType="submit" >
                    提交
                </Button>
            </Form.Item>
        </Form>
    }

    render() {
        const data = this.state.data
        return <>
            <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ width: 200, display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <UserAvatar nickname={data?.user?.nickname} size={80} avatar={data?.user?.avatar} id={data?.user?.id} vip={data?.user?.group === "vip"} />
                </div>

                <div style={{ flexGrow: 1 }}>
                    <Row>
                        {data?.name ?
                            <Col span={6}>
                                <Statistic title="名称" value={data?.name} />
                            </Col> :
                            <Col span={6}>
                                <Statistic title="营销计划名" value={data?.plan.display_name} />
                            </Col>
                        }
                        <Col span={6}>
                            <Statistic title="积分余额" value={data?.coin_balance} />
                        </Col>
                        <Col span={6}>
                            <Statistic title="有效时间" value={`${dayjs(data?.active_at).format("YYYY-MM-DD")}`} />
                        </Col>
                        <Col span={6}>
                            <Statistic title="过期时间" value={`${dayjs(data?.expired_at).format("YYYY-MM-DD")}`} />
                        </Col>
                    </Row>
                    <br></br>
                    <Row>
                        <Col span={6}>
                            <Tooltip title="结算周期，分为单次和循环，表示此计划下的营销行为可完成单次还是无限次。">
                                <Statistic title={<span>结算周期 <InfoCircleOutlined /></span>} value={settleTypeLabel(data?.plan.settle_type)} />
                            </Tooltip>
                        </Col>
                        <Col span={6}>
                            <Statistic title="销售总量" value={data?.total_price} />
                        </Col>
                        <Col span={6}>
                            <Statistic title="限制销售次数" value={data?.limit_count || "无限制"} />
                        </Col>
                        <Col span={6}>
                            <Statistic title="积分名称" value={data?.plan.coin_unit} />
                        </Col>
                    </Row>
                </div>
            </div>
            <br />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Table bordered title={() => "可兑换报酬"} dataSource={data?.plan_rewards} style={{ flexGrow: 1, marginRight: 10 }} rowKey={d => d.reference_id} pagination={false} >
                    <Table.Column align="center" title="报酬名" dataIndex="name" key="name" />
                    <Table.Column align="center" title="兑换需要积分" dataIndex="coin_num" key="coin_num" />

                </Table>
                <Table bordered title={() => "计划可用营销品"} dataSource={data?.plan_sales} style={{ flexGrow: 1 }} rowKey={d => d.reference_id} pagination={false}>
                    <Table.Column align="center" title="商品名" dataIndex="name" key="name" />
                    <Table.Column align="center" title="销售价格(分)" dataIndex="price" key="price" />
                    <Table.Column align="center" title="可得积分" dataIndex="coin_num" key="coin_num" />

                </Table>
            </div>

            <Tabs onChange={this.handleTabsChange.bind(this)}>
                <TabPane tab="邀请记录" key="join_log">
                    <Table dataSource={this.state.invite_code_logs?.entries || []} pagination={{
                        onChange: (page, size) => { this.loadInviteLogPagnation(page, size) },
                        total: this.state.invite_code_logs?.total_entries,
                        current: this.state.invite_code_logs?.page_number,
                        pageSize: this.state.invite_code_logs.page_size,
                        showQuickJumper: true
                    }} rowKey={d => d.id} style={{ flexGrow: 1 }}>

                        <Table.Column align="center" title="销售时间" dataIndex="inserted_at" key="inserted_at" render={text => <UTCTimeAgo date={text} />} />
                        <Table.Column align="center" title="关联用户" dataIndex={["reference_data", "user"]} key={["reference_data", "user"]} render={user => <UserAvatar nickname={user?.nickname} size={40} avatar={user?.avatar} id={user?.id} vip={user?.group === "vip"} />} />
                        <Table.Column align="center" title="销售价格" dataIndex="price" key="price" />
                        <Table.Column align="center" title="预计获得积分" dataIndex="adding_num" key="adding_num" />
                        <Table.Column align="center" title="获得积分" dataIndex="coin_num" key="coin_num" />
                        <Table.Column align="center" title="结算时间" dataIndex="settle_at" key="settle_at" render={text => <LocalTimeAgo date={text}></LocalTimeAgo>} />
                        <Table.Column align="center" title="状态" dataIndex="status" key="status" render={(text, item) => {
                            switch (text) {
                                case "pending":
                                    return "等待结算";
                                case "success":
                                    return "结算完成";
                                case 'failed':
                                    return <Tooltip title={"用户已退款"}>结算失败 <QuestionCircleOutlined /></Tooltip>;
                            }
                        }} />
                    </Table>
                </TabPane>

                <TabPane tab="报酬提取申请" key="reward_convert">
                    <Table dataSource={this.state.invite_code_reward_converts?.entries} pagination={{
                        onChange: (page, size) => { this.loadSettleCyclePagnation(page, size) },
                        total: this.state.invite_code_reward_converts?.total_entries,
                        current: this.state.invite_code_reward_converts?.page_number,
                        pageSize: this.state.invite_code_reward_converts?.page_size
                    }} rowKey={d => d.id} style={{ flexGrow: 1 }}>
                        <Table.Column align="center" title="申请时间" dataIndex="inserted_at" key="inserted_at" render={text => <UTCTimeAgo date={text} />} />
                        <Table.Column align="center" title="报酬名" dataIndex="reward_name" key="reward_name" />
                        <Table.Column align="center" title="申请量" dataIndex="convert_num" key="convert_num" />
                        <Table.Column align="center" title="积分消耗" dataIndex="coin_cost" key="coin_cost" />

                        <Table.Column align="center" title="状态" dataIndex="status" key="status" render={(text) => {
                            switch (text) {
                                case "pending":
                                    return "等待审核";
                                case "wait_release":
                                    return "等待发放";
                                case 'finish':
                                    return "发放完成";
                                case "failed":
                                    return "审核失败";
                            }
                        }} />
                        <Table.Column align="center" title="申请原因" dataIndex="reason" key="reason" />
                        <Table.Column align="center" title="审核拒绝原因" dataIndex="failed_message" key="failed_message" />
                    </Table>
                </TabPane>
            </Tabs>


        </>
    }
}



const mapStateToProps = ({ global }) => {
    return {
        socket: global.socket
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(InviteCodeDetail)